@charset "utf-8";

@import "./themes/variables";
@import "bulma";
@import "bulma-switch";
@import "bulma-pageloader";
@import "@creativebulma/bulma-divider";
@import "@creativebulma/bulma-tooltip";
@import "./themes/custom";

.switch[type=checkbox]:focus + label:before,
.switch[type=checkbox]:focus + label:after {
  outline: 0;
}

.border-dashed {
  border: 2px dashed $border;
}

.has-background-stadium {
  background-color: #465770;
}
