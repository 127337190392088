@import url("https://fonts.googleapis.com/css?family=Bungee&display=swap");
@import url("https://rsms.me/inter/inter.css");

@font-face {
  font-family: "Graphik Arabic Medium";
  src: local("Qatar2022Arabic Bold"), url("../fonts/Graphik-Arabic-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Qatar2022Arabic Bold";
  src: local("Qatar2022Arabic Bold"), url("../fonts/Qatar2022Arabic-Bold.ttf") format("truetype");
  font-weight: bold;
}  

.has-text-muted {
  color: $grey-lighter;
}

.hero.is-fullheight {
  min-height: var(--app-height);
  overflow: hidden;
}

.hero-ball {
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--app-height);
  height: var(--app-height);
  background-position: right center;
  background-size: cover;
  z-index: 0;
  left: -50%;
}

@include from($tablet) {
  .mx-6-tablet {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
}

.bracket-match {
  min-height: 7rem;
}

@include from($desktop) {
  .hero-ball {
    left: -45%;
  }
  .hero-box {
    background-color: rgba($primary, 0.25);
    padding: 1rem;
    border-radius: 1rem;
  }
  .is-justify-content-end-desktop {
    justify-content: end !important;
  }
  
  .bracket-col-1 .bracket-match {
    min-height: 14rem;
  }
  
  .bracket-col-2 .bracket-match {
    min-height: 28rem;
  }
  
  .bracket-col-3 .bracket-match,
  .bracket-col-4 .bracket-match {
      min-height: 56rem;
  }
}

@include from($widescreen) {
  .hero-ball {
    left: -30%;
  }
}

.modal {
  position: fixed !important;
}

// -------
// Pricing
// -------

.pricing-card {
  padding: 2rem;
  background-color: #800E30;
  border-radius: 32px;
  @include from($desktop) {
    margin-bottom: -0.75rem;
  }
}

.pricing-card-1 {
  background-color: $light;
  box-shadow: 0 0 0 2px #800E30, 0 0 0 (2px + 2) #800E30;
  @include from($desktop) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-bottom: -2.75rem;
  }
}

@include from($desktop) {
  .pricing-card-0 {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .pricing-card-2 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.is-line-through {
  text-decoration: line-through;
}

.neon-text {
  color: #fff;
  text-shadow:
    /* White glow */
    0 0 4px #fff,
    0 0 5px #fff,
    0 0 11px #fff,
}
